<template>
  <component :is="icon" :size="size" :color="color" :strokeWidth="strokeWidth" />
</template>

<script>
import {
  Pencil,
  Bell,
  Check,
  ChevronDown,
  XCircle,
  Plus,
  Minus,
  RefreshCw,
  Volume2,
  Upload,
  Home,
  CalendarDays,
  User,
  UserPlus,
  X,
  Eye,
  Search,
  SlidersHorizontal,
  HelpCircle,
  Info,
  ChevronLeft,
  ChevronRight,
  ExternalLink,
  Loader,
} from 'lucide-vue-next';

const icons = {
  Pencil,
  Bell,
  Check,
  ChevronDown,
  XCircle,
  Plus,
  Minus,
  RefreshCw,
  Volume2,
  Upload,
  Home,
  CalendarDays,
  User,
  UserPlus,
  X,
  Eye,
  Search,
  SlidersHorizontal,
  HelpCircle,
  Info,
  ChevronLeft,
  ChevronRight,
  ExternalLink,
  Loader,
};

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 18,
    },
    color: {
      type: String,
      default: 'currentColor',
    },
    strokeWidth: {
      type: Number,
      default: 2,
    },
  },
  computed: {
    icon() {
      return icons[this.name];
    },
  },
};
</script>
