import * as Yup from 'yup';
import {
  defaultRequiredRule,
  email,
  password,
  acceptTerm,
  confirmPassword,
  fileCheck,
  idNumberCheck,
  fieldsCanNotEmptyCheck,
} from './formValidationRules';

const signup = [
  Yup.object({
    unitType: Yup.number(),
    unitName: Yup.string().when('unitType', {
      is: 1,
      then: (schema) => schema.required('請填寫單位全銜'),
    }),
    headerName: Yup.string().when('unitType', {
      is: 1,
      then: (schema) => schema.required('請填寫負責人姓名'),
    }),
    unNumber: Yup.string().when('unitType', {
      is: 1,
      then: (schema) => schema.matches(/\d{8}/, '無效的統一編號').required('請填寫統一編號'),
    }),
    name: Yup.string().when('unitType', {
      is: 2,
      then: (schema) => schema.required('請填寫姓名'),
    }),
    iDNumber: Yup.string().when('unitType', {
      is: 2,
      then: (schema) => idNumberCheck(schema),
    }),
    password,
    confirmPassword,
    contactName: defaultRequiredRule('請填寫姓名'),
    contactEmail: email.required('請填寫Email'),
    contactTel: defaultRequiredRule('請填寫電話'),
    contactMobile: defaultRequiredRule('請填寫手機號碼'),
    contactFax: Yup.string(),
    address: defaultRequiredRule('請填寫詳細地址'),
    otherName: Yup.string(),
    otherEmail: email,
    otherTel: Yup.string(),
    otherMobile: Yup.string(),
    file1: fileCheck(3, ['jpg', 'jpeg', 'pdf']),
    file2: Yup.mixed().when('unitType', {
      is: 1,
      then: (schema) => fileCheck(3, ['jpg', 'jpeg', 'pdf'], schema),
    }),
    file3: Yup.mixed().when('unitType', {
      is: 1,
      then: (schema) => fileCheck(3, ['jpg', 'jpeg', 'pdf'], schema),
    }),
    acceptTerm,
  }),
];

const login = Yup.object({
  username: defaultRequiredRule('請填寫公司統編或身分證字號'),
  password: defaultRequiredRule('請填寫密碼'),
  captcha: defaultRequiredRule('請填寫驗證碼'),
});

const concertRent = [
  Yup.object({
    acceptTerm,
  }),
  Yup.object({
    activityName: defaultRequiredRule('請填寫活動名稱'),
    activityNameEN: Yup.string(),
    activitySessionType: defaultRequiredRule('請選擇檔期屬性'),
    activityType: defaultRequiredRule('請選擇活動型式'),
    activityArea: Yup.string(),
    activityCharge: Yup.string(),
    totalStartDate1: Yup.string(),
    totalEndDate1: Yup.string(),
    showStartDate1: Yup.string(),
    showEndDate1: Yup.string(),
    sessionList1: Yup.array()
      .of(
        Yup.object({
          showDate: Yup.date().required('請填寫日期'),
          sessions: Yup.number().min(1, '請填寫至少${min}場').required('請填寫場數'),
        }),
      ).min(1, '演出日請填寫至少${min}日'),
    entryList1: Yup.array()
      .of(
        Yup.object({
          entryExitDate: Yup.date().required('請填寫日期'),
          timeDuration: Yup.array().min(2, '請選擇至少${min}個時段').required('請選擇時段'),
        }),
      ).min(1, '進場日請填寫至少${min}日'),
    exitsList1: Yup.array()
      .of(
        Yup.object({
          entryExitDate: Yup.date().required('請填寫日期'),
          timeDuration: Yup.array().min(1, '請選擇至少${min}個時段').required('請選擇時段'),
        }),
      ).min(1, '撤場日請填寫至少${min}日'),
    totalStartDate2: Yup.string().nullable(),
    totalEndDate2: Yup.string().nullable(),
    showStartDate2: fieldsCanNotEmptyCheck(
      ['totalStartDate2', 'totalEndDate2'],
      (schema) => schema.required('請選擇檔期'),
    ).nullable(),
    showEndDate2: fieldsCanNotEmptyCheck(
      ['totalStartDate2', 'totalEndDate2'],
      (schema) => schema.required('請選擇檔期'),
    ).nullable(),
    sessionList2: fieldsCanNotEmptyCheck(
      ['totalStartDate2', 'totalEndDate2', 'showStartDate2', 'showEndDate2'],
      (schema) => schema.of(
        Yup.object({
          showDate: Yup.date().required('請填寫日期'),
          sessions: Yup.number().min(1, '請填寫至少${min}場').required('請填寫場數'),
        }),
      ).min(1, '演出日請填寫至少${min}日'),
      Yup.array(),
    ),
    entryList2: fieldsCanNotEmptyCheck(
      ['totalStartDate2', 'totalEndDate2', 'showStartDate2', 'showEndDate2'],
      (schema) => schema.of(
        Yup.object({
          entryExitDate: Yup.date().required('請填寫日期'),
          timeDuration: Yup.array().min(2, '請選擇至少${min}個時段').required('請選擇時段'),
        }),
      ),
      Yup.array(),
    ),
    exitsList2: fieldsCanNotEmptyCheck(
      ['totalStartDate2', 'totalEndDate2', 'showStartDate2', 'showEndDate2'],
      (schema) => schema.of(
        Yup.object({
          entryExitDate: Yup.date().required('請填寫日期'),
          timeDuration: Yup.array().min(1, '請選擇至少${min}個時段').required('請選擇時段'),
        }),
      ),
      Yup.array(),
    ),
  }),
  Yup.object({
    file1: fileCheck(20, ['pdf']),
    file2: fileCheck(20, ['pdf']),
    file3: fileCheck(20, ['pdf']),
  }),
  Yup.object({
    contactName: defaultRequiredRule('請填寫姓名'),
    contactEmail: email.required('請填寫Email'),
    contactTel: defaultRequiredRule('請填寫電話'),
    contactMobile: defaultRequiredRule('請填寫手機號碼'),
    address: defaultRequiredRule('請填寫聯絡地址'),
    otherName: Yup.string(),
    otherEmail: email,
    otherTel: Yup.string(),
    otherMobile: Yup.string(),
  }),
];

const culturalRent = [
  Yup.object({
    acceptTerm,
  }),
  Yup.object({
    activityName: defaultRequiredRule('請填寫活動名稱'),
    activityNameEN: Yup.string(),
    activitySource: defaultRequiredRule('請選擇活動來源'),
    activityType: defaultRequiredRule('請選擇活動型式'),
    activityArea: Yup.string(),
    activityCharge: Yup.string(),
    activityPeople: Yup.number().required('請填寫預估總參觀人次'),
    totalStartDate1: Yup.string().required('請選擇檔期'),
    totalEndDate1: Yup.string().required('請選擇檔期'),
    showStartDate1: Yup.string().required('請選擇展演時段'),
    showEndDate1: Yup.string().required('請選擇展演時段'),
    showDayCount1: Yup.number().required('請記得選擇展演時段').nullable(),
    totalStartDate2: Yup.string().nullable(),
    totalEndDate2: Yup.string().nullable(),
    showStartDate2: fieldsCanNotEmptyCheck(
      ['totalStartDate2', 'totalEndDate2'],
      (schema) => schema.required('請選擇檔期'),
    ).nullable(),
    showEndDate2: fieldsCanNotEmptyCheck(
      ['totalStartDate2', 'totalEndDate2'],
      (schema) => schema.required('請選擇檔期'),
    ).nullable(),
    showDayCount2: fieldsCanNotEmptyCheck(
      ['totalStartDate2', 'totalEndDate2', 'showStartDate2', 'showEndDate2'],
      (schema) => schema.required('請記得選擇展演時段'),
      Yup.number(),
    ).nullable(),
  }),
  Yup.object({
    file1: fileCheck(20, ['pdf']),
    file2: fileCheck(20, ['pdf']),
  }),
  Yup.object({
    contactName: defaultRequiredRule('請填寫姓名'),
    contactEmail: email.required('請填寫Email'),
    contactTel: defaultRequiredRule('請填寫電話'),
    contactMobile: defaultRequiredRule('請填寫手機號碼'),
    address: defaultRequiredRule('請填寫詳細地址'),
    otherName: Yup.string(),
    otherEmail: email,
    otherTel: Yup.string(),
    otherMobile: Yup.string(),
  }),
];

const forgotPassword = Yup.object({
  username: defaultRequiredRule('請填寫公司統一編號/個人身份證字號'),
  email: defaultRequiredRule('請填寫Email'),
  captcha: defaultRequiredRule('請填寫驗證碼'),
});

const resetPassword = Yup.object({
  password,
  confirmPassword,
});

const updatePassword = Yup.object({
  oldPassword: defaultRequiredRule('請填寫舊密碼'),
  password,
  confirmPassword,
  captcha: defaultRequiredRule('請填寫驗證碼'),
});

const updateProfile = Yup.object({
  contactName: defaultRequiredRule('請填寫聯絡人姓名'),
  contactEmail: email.required('請填寫Email'),
  contactTel: defaultRequiredRule('請填寫電話'),
  contactMobile: defaultRequiredRule('請填寫手機號碼'),
  contactFax: Yup.string(),
  address: defaultRequiredRule('請填寫詳細地址'),
  otherContact: Yup.string(),
  otherEmail: email,
  otherTel: Yup.string(),
  otherMobile: Yup.string(),
});

export {
  signup,
  login,
  concertRent,
  culturalRent,
  forgotPassword,
  resetPassword,
  updatePassword,
  updateProfile,
};
