<template>
  <div class="form-item">
    <label class="form-label" :class="{ 'is-required': isRequired }" :for="fieldName">
      {{ fieldLabel }}
      <slot name="label" />
      <Popper
        class="tooltip"
        v-if="fieldHelper"
        :content="fieldHelper"
        arrow
        :hover="true"
        placement="right"
      >
        <Icon name="Info"></Icon>
      </Popper>
    </label>
    <div class="form-group">
      <Field :name="fieldName" v-slot="{ field, handleChange, errors, meta }">
        <input
          :type="formateType"
          class="form-input"
          :class="{'is-invalid': errors.length > 0 }"
          min="0"
          v-bind="field"
          :placeholder="placeHolder"
          @input="handler($event, handleChange)"
          @change="handler($event, handleChange)"
          :readonly="isReadonly"
        >
        <a
          v-if="fieldType === 'password'"
          class="form-input-addon"
          href="#"
          @click.prevent="(isPasswordReveal = !isPasswordReveal)"
        >
          <i class="icon" :class="isPasswordReveal ? 'icon-eye-open': 'icon-eye-off' " aria-hidden="true"></i>
        </a>
        <div class="form-input-addon" v-if="isAsync && meta.pending">
          <Icon name="Loader"></Icon>檢查中...
        </div>

      </Field>
    </div>
    <div class="form-promp is-notify">
      <slot name="notify" />
    </div>
    <div class="form-promp is-invalid">
      <ErrorMessage :name="fieldName" />
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Icon.vue';
import { Field, ErrorMessage } from 'vee-validate';

export default {
  props: {
    fieldName: String,
    fieldLabel: String,
    fieldType: {
      type: String,
      default: 'text',
    },
    fieldHelper: {
      type: String,
      default: null,
    },
    isRequired: {
      type: Boolean,
      default: true,
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
    isAsync: {
      type: Boolean,
      default: false,
    },
    placeHolder: {
      type: String,
      default: null,
    },
  },
  components: {
    Field,
    ErrorMessage,
    Icon,
  },
  data() {
    return {
      isPasswordReveal: false,
    };
  },
  methods: {
    handler(e, handleChange) {
      let val = e.target.value;
      if (this.fieldType === 'number') val = Number(val);
      handleChange(val);
    },
  },
  computed: {
    formateType() {
      if (this.fieldType === 'password') {
        return this.isPasswordReveal ? 'text' : 'password';
      }

      return this.fieldType;
    },
  },
};
</script>
