import * as Yup from 'yup';
import _ from 'lodash';
import api from '../api/api';

const defaultRequiredRule = (message) => Yup.string().required(message);

const email = Yup.string()
  .email('無效的 Email');

const password = Yup.string()
  .matches(/[@$!%*#?&]+/, '缺少特殊符號')
  .matches(/[a-z]+/, '缺少小寫英文')
  .matches(/[A-Z]+/, '缺少大寫英文')
  .matches(/\d+/, '缺少數字')
  .min(12, '至少${min}碼')
  .required('請填寫密碼');

const confirmPassword = Yup.string()
  .oneOf([Yup.ref('password'), null], '與密碼不一致')
  .required('請再填寫一次密碼');

const fileCheck = (limitSize, acceptTypesArray, schema) => {
  const refrence = schema || Yup.mixed();
  return refrence
    .test('isSizeOver', `檔案大小超過 ${limitSize} MB`, (file) => {
      if (typeof file === 'string') return true; // server 回傳檔案路徑
      return file && file.size <= limitSize * 1024 * 1024;
    })
    .test('isTypeAccepted', `格式只允許 ${acceptTypesArray.join(', ')}`, (file) => {
      if (typeof file === 'string') return true; // server 回傳檔案路徑
      const type = file?.type || null;
      if (!type) return false;
      const fileExtension = type.includes('/') ? type.split('/')[1].toLowerCase() : type;
      return _.includes(acceptTypesArray, fileExtension);
    })
    .required('請上傳檔案');
};

const idNumberCheck = (schema) => schema
  .test( // https://github.com/jquense/yup/issues/851
    async (value, context) => {
      try {
        await Yup.string().matches(/^[A-Z]{1}[1-2]{1}[0-9]{8}$/, '錯誤的格式').required('請填寫身分證字號').validate(value);
        const isValid = await api.checkIdNumber(value);
        if (!isValid) throw new Error('無效的身分證字號');
      } catch (error) {
        return context.createError({ message: error.message });
      }

      return true;
    },
  );

const fieldsCanNotEmptyCheck = (fieldsName, validation, schema) => {
  const refrence = schema || Yup.string();
  const then = _.isFunction(validation) ? validation : validation.then;
  const otherwise = _.isFunction(validation) ? null : validation.otherwise;

  return refrence.when(fieldsName, {
    is: (...values) => !_.some(values, _.isEmpty),
    then,
    otherwise,
  });
};

const acceptTerm = Yup.boolean().oneOf([true], '請閱讀並同意隱私權使用政策');

export {
  defaultRequiredRule,
  email,
  password,
  acceptTerm,
  confirmPassword,
  fileCheck,
  idNumberCheck,
  fieldsCanNotEmptyCheck,
};
